import React, { Component } from 'react';
import PaginatedTable from "./PaginatedTable.jsx";

function WithRelatedIncidentsTable(wrapperProps) {

    return class extends Component {
        constructor(props) {
            super(props);
        }

        dataProcessor(json) {
            if(json && json.data && json.data.relationships) {
                return json.data.relationships[wrapperProps.relationship].data;
            }
            return '';
        }

        trMapper(row) {
            let incidentLink = row.id ?
                <a href={'/incidents/' + row.id}>{'INC-' + row.id}</a> : 'None';
            let cols = this.props.headers.map((header) => {
                let value = row[header[this.props.headerKeyAttribute]] || 'Not set';
                if ('updated_at' == header[this.props.headerKeyAttribute] || 'score' == header[this.props.headerKeyAttribute]) {
                    value = <div dangerouslySetInnerHTML={{__html: value}}></div>;
                }
                if ('id' == header[this.props.headerKeyAttribute]) {
                    value = incidentLink;
                }
                return <td data-column={header['name']} data-sort-key={row.id} key={header['name']}>{value}</td>;
            });
            return (
                <tr key={row.id}>
                    {cols}
                </tr>
            )
        }

        render() {
            let incidentId = this.props.incidentId;
            let parentAttribute = 'related_incidents';
            let jsonBasePath = `/incidents/${incidentId}/related_incidents/${wrapperProps.relationship}`;
            return <PaginatedTable tableId={wrapperProps.tableId} dataProcessor={this.dataProcessor} api={jsonBasePath}
                                   parentAttribute={parentAttribute} unsortableColumns={['Infection Confidence']}
                                   {...this.props} {...wrapperProps} trMapper={this.trMapper}/>;
        }
    };
}

export default WithRelatedIncidentsTable;
