import React from 'react';

const HelpPopup = (props) => {
    return (
        <div className="inline_help_popup">
            <a className="inline_help_popup_button" role="button"
                aria-controls={props.id} aria-expanded="true"
                aria-haspopup="true" href={"#" + props.id}/>
            <nav id={props.id} aria-hidden="true">{props.message}</nav>
        </div>
    );
};

export default HelpPopup;
