import React, { Component } from 'react';
import WithRelatedIncidentsTable from "./WithRelatedIncidentsTable.jsx";

const byHostProps = {
    tableId: 'related_incidents_by_target_host',
    relationship: 'related_incidents_by_target_host'
};

let RelatedIncidentsByHost = WithRelatedIncidentsTable(byHostProps);
export default RelatedIncidentsByHost;
