import React, { Component } from 'react';
import PropTypes from 'prop-types'
import TableHeader from "./TableHeader.jsx";

// Currently we are supporting single column sorting only.
class PaginatedTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            currentPage: 1,
            currentSortColumn: props.defaultSortColumn || null,
            currentSortDirection: props.defaultSortDirection
        }
    }

    columnHeaders() {
        let self = this;
        return this.props.headers.map(function (header) {
            return <TableHeader {...header} sortHandler={self.sortHandler.bind(self)}
                sortable={self.props.unsortableColumns.indexOf(header.name) === -1}
                sorted={self.sortCssForHeader(header[self.props.headerKeyAttribute])}
                key={header[self.props.headerKeyAttribute]}
                headerkey={header[self.props.headerKeyAttribute]}/>;
        });
    }

    tableRows() {
        let trMapper = this.props.trMapper || this.trMapper;
        return this.state.rows.length > 0 ? this.state.rows.map(trMapper.bind(this)) : null;
    }

    // Default trMapper function. Override this to customize.
    trMapper(row) {
        let cols = this.props.headers.map((header) => {
            let value = row[header[this.props.headerKeyAttribute]] || 'Not set';
            return <td data-column={header['name']} data-sort-key={row.id} key={header['name']}>{value}</td>;
        });
        return (
            <tr key={row.id}>
                {cols}
            </tr>
        )
    }

    fetchNextPage(initial) {
        if (!this.state.endOfPaginaton) {
            if (initial) {
                this.state.rows = [];
                this.state.currentPage = 1;
            }
            else {
                this.state.currentPage = this.state.currentPage + 1;
            }
            this.fetchData();
        }
    }

    scrollHandler(e) {
        let scrollerEndPoint = e.target.scrollHeight - $(e.target).height();
        if (e.target.scrollTop > 0 && e.target.scrollTop === scrollerEndPoint) {
            this.fetchNextPage();
        }
    }

    buildPaginateUrl() {
        let url = this.props.api;
        url = `${url}?${this.props.parentAttribute}${this.props.pageAttributes.limit}=${this.props.perPage}`;
        url = `${url}&${this.props.parentAttribute}${this.props.pageAttributes.number}=${this.state.currentPage}`;
        if (this.state.currentSortColumn) {
            url = `${url}&${this.props.parentAttribute}${this.props.pageAttributes.direction}=${this.state.currentSortDirection}`;
            url = `${url}&${this.props.parentAttribute}${this.props.pageAttributes.order}=${this.state.currentSortColumn}`;
        }
        return url;
    }

    sortCssForHeader(name) {
        let sortCssForHeader = null;
        if ('DESC' === this.state.currentSortDirection) {
            sortCssForHeader = 'reversed';
        } else {
            if ('ASC' === this.state.currentSortDirection) {
                sortCssForHeader = 'normal';
            }
        }
        if (this.state.currentSortColumn == name) {
            return sortCssForHeader;
        } else {
            return null;
        }
    }

    sortHandler(headerKey) {
        if (this.state.inProgress) {
            return null;
        }
        let latestSortOrder = null;
        if ('DESC' === this.state.currentSortDirection) {
            latestSortOrder = 'ASC';
        }
        if ('ASC' === this.state.currentSortDirection) {
            latestSortOrder = 'DESC';
        }
        this.state.currentSortColumn = headerKey;
        this.state.currentSortDirection = latestSortOrder;
        this.state.endOfPaginaton = false;
        this.sortCssForHeader();
        this.fetchNextPage(true);
    }

    // Default dataProcessor function. Override this to customize.
    dataProcessor(data) {
        if (this.props.dataProcessor) {
            return this.props.dataProcessor(data);
        }
        else {
            return data;
        }
    }

    fetchData() {
        this.setState({inProgress: true});
        tc.xhr.get(this.buildPaginateUrl()).then((json) => {
            this.setState({inProgress: false, message: this.props.defaultMessage});
            if (json) {
                let data = this.dataProcessor(json);
                if (data.length == 0) {
                    this.state.endOfPaginaton = true
                }
                else {
                    requestAnimationFrame(() => {
                        this.setState({
                            rows: this.state.rows.concat(data),
                            inProgress: false
                        });
                    })
                }
            }
            else {
                this.setState({inProgress: false, message: this.props.noRecordsFoundMessage});
            }
        });
    }

    componentDidMount() {
        this.setState({inProgress: true});
        this.fetchNextPage(true);
        this.sortCssForHeader();
    }

    render() {
        return (
            <div>
                <table id={this.props.tableId} onScroll={this.scrollHandler.bind(this)}
                       data-rows={this.state.rows.size} react-sortable='true'>
                    <thead>
                        <tr key={-1}>{this.columnHeaders()}</tr>
                    </thead>
                    <tbody>
                        {this.tableRows()}
                    </tbody>
                </table>
            </div>
        )
    }
}

PaginatedTable.defaultProps = {
    tableId: 'tableId',
    noRecordsFoundMessage: 'No data records found.',
    defaultMessage: 'No data available.',
    perPage: 5,
    parentAttribute: '',
    pageAttributes: {
        limit: '[page][limit]',
        number: '[page][number]',
        direction: '[page][direction]',
        order: '[page][order]'
    },
    defaultSortDirection: 'DESC',
    defaultSortColumn: null,
    unsortableColumns: [],
    headerKeyAttribute: 'db_column',
    headers: []
};

PaginatedTable.propTypes = {
    tableId: PropTypes.string.isRequired,
    noRecordsFoundMessage: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
    perPage: PropTypes.number.isRequired,
    parentAttribute: PropTypes.string,
    pageAttributes: PropTypes.object.isRequired,
    defaultSortDirection: PropTypes.string,
    defaultSortColumn: PropTypes.string,
    unsortableColumns: PropTypes.array,
    headerKeyAttribute: PropTypes.string,
    headers: PropTypes.array.isRequired
};

export default PaginatedTable
