import React from 'react'
import ReactDOM from 'react-dom'
import DialogComponent from './DialogComponent.jsx';

class QuarantineAttemptsTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [], sourceData: [], currentPage: 0, totalPages: 1,
            inProgress: false, currentMessageBody: false, message: this.props.defaultMessage
        };
    }

    toggleDialog(e) {
        this.setState({currentMessageBody: !this.state.currentMessageBody});
    }

    showDialog(row, e) {
        let body =
            <ul>
                <li><b>Message ID: </b>{row.message_id}</li>
                <li><b>Incident: </b>{row.incident_id || 'None'}</li>
                <li><b>Recipient: </b>{row.mailbox}</li>
                <li><b>Sender: </b>{row.sender_address}</li>
            </ul>;
        this.setState({currentMessageBody: body});
    }

    timeFilteredData() {
        // Filter by selected date range
        let bisect = d3.bisector((d) => new Date(d.created_at)).left,
            data = this.state.sourceData
                .slice(bisect(this.state.sourceData, top40.time.selectedDateRange()[0]));
        return data;
    }

    init(sourceData) {
        this.setState({sourceData: sourceData});
        this.redraw();

        $('[id="time_range_preset"]').on('change', this.redraw.bind(this));
        $('[href="#dashboards:quarantine_summary_tab"]').on('click', this.redraw.bind(this));
    }

    fetchNextPage(initial) {
        initial = initial || false;
        if (this.state.currentPage < this.state.totalPages) {
            let nextPageData = this.timeFilteredData().slice(this.state.currentPage * (this.props.size),
                (this.state.currentPage + 1) * this.props.size);

            requestAnimationFrame(() => {
                let rows = initial ? nextPageData : this.state.rows.concat(nextPageData);
                let currentPage = initial ? 1 : this.state.currentPage + 1;
                this.setState({rows: rows, currentPage: currentPage});
            });
        }
    }

    redraw() {
        requestAnimationFrame(() => {
            this.setState({
                rows: [],
                currentPage: 0,
                totalPages: (this.timeFilteredData().length / this.props.size)
            });
            this.fetchNextPage(true);
        });
    }

    scrollHandler(e) {
        let scrollerEndPoint = e.target.scrollHeight - e.target.offsetHeight;
        if (e.target.scrollTop > 0 && e.target.scrollTop >= scrollerEndPoint) {
            this.fetchNextPage();
        }
    }

    componentDidMount() {
        let timeAgo = Report.timeAgo('P90D'),
            jsonPath = '/dashboards/quarantines/attempts.json' + timeAgo;
        this.setState({inProgress: true});
        tc.xhr.get(jsonPath).then((sourceData) => {
            this.setState({inProgress: false, message: this.props.defaultMessage});
            if (sourceData && sourceData.length) {
                requestAnimationFrame(() => {
                    this.init(sourceData);
                    this.setState({inProgress: false, message: this.props.noRecordsFoundMessage});
                })
            }
        });

        document.addEventListener('live:before-update', function (event) {
            if (event.target.dataset.key === 'quarantine_attempts') {
                ReactRailsUJS.unmountComponents(ReactDOM.findDOMNode(event.target));
            }
        });

        document.addEventListener('live:after-update', function (event) {
            if (event.target.dataset.key === 'quarantine_attempts') {
                ReactRailsUJS.mountComponents(event.target)
            }
        })
    }

    trMapper(row) {
        let incidentLink = row.incident_id ?
            <a href={'/incidents/' + row.incident_id}>{'INC-' + row.incident_id}</a> : 'None';
        return (
            <tr key={new Date(row.created_at).valueOf()}>
                <th data-column='Message ID' scope="row">
                    <a href="#" hasdialog="description" onClick={this.showDialog.bind(this, row)}>{row.message_id}</a>
                </th>
                <td data-column='Incident' data-sort-key={row.incident_id}>{incidentLink}</td>
                <td data-column='Recipient'>{row.mailbox}</td>
                <td data-column='Sender'>{row.sender_address}</td>
                <td data-column='Result'>
                    <span data-operation={'quarantine_' + row.operation} data-status={row.state}>
                        {row.state}
                    </span>
                </td>
            </tr>
        )
    }

    renderTable() {
        let tableRows = this.state.rows.length > 0 ? this.state.rows.map(this.trMapper.bind(this)) : null;
        return (
            <table id="dashboards:quarantines:attempts" sortable="true" onScroll={this.scrollHandler.bind(this)}>
                <thead>
                    <tr>
                        <th scope="col" title='Message ID'>Message ID</th>
                        <th scope="col" title='Incident'>Incident</th>
                        <th scope="col" title='Recipient'>Recipient</th>
                        <th scope="col" title='Sender'>Sender</th>
                        <th scope="col" title='Result'>Result</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        )
    }

    render() {
        let dialog = this.state.currentMessageBody ?
            <DialogComponent body={this.state.currentMessageBody} header='MessageId'
                             toggleDialog={this.toggleDialog.bind(this)}/> : null;
        let progress = this.state.inProgress ?
            <div role="row">
                <p><span data-status="in_progress">Loading&hellip;</span>
                    Loading report data&hellip;
                </p>
            </div> : null;
        let message = this.state.rows.length < 1 && !this.state.inProgress ? this.state.message : null;
        return (
            <div className={this.state.rows.length > 0 ? 'plus-rows' : ''}>
                {this.renderTable()}
                <div role="row">
                    <p title='default-message'>
                        {message}
                    </p>
                </div>
                {progress}
                {dialog}
            </div>
        )
    }
}

QuarantineAttemptsTableComponent.defaultProps = {
    noRecordsFoundMessage: 'No quarantine attempts found.',
    defaultMessage: 'No data available.',
    size: 5
};

export default QuarantineAttemptsTableComponent
