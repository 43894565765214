import React, { Component } from 'react';
import WithRelatedIncidentsTable from "./WithRelatedIncidentsTable.jsx";

const byEmailProps = {
    tableId: 'related_incidents_by_email_alert',
    relationship: 'related_incidents_by_emails'
};

let RelatedIncidentsByEmail = WithRelatedIncidentsTable(byEmailProps);
export default RelatedIncidentsByEmail;
