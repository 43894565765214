import React, { Component } from 'react';
import WithRelatedIncidentsTable from "./WithRelatedIncidentsTable.jsx";

const byUserProps = {
    tableId: 'related_incidents_by_target_user',
    relationship: 'related_incidents_by_target_user'
};

let RelatedIncidentsByUser = WithRelatedIncidentsTable(byUserProps);
export default RelatedIncidentsByUser;
