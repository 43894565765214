import React from 'react'
import PropTypes from 'prop-types';

class TeamAndAccountSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTeamId: props.selectedTeamId,
            selectedTeamAccounts: props.selectedTeamAccounts,
            selectedAccountId: props.selectedAccountId
        };
    }

    teamOptions() {
        return this.props.allTeams.map(function (team) {
            return <option value={team.id} key={team._gid}>{team.name}</option>;
        });
    }

    onTeamChange(event) {
        let selectedTeamId = event.target.value;

        fetch(`/teams/${selectedTeamId}/accounts.json`,
            {headers: {Accept: 'application/json'}, credentials: 'same-origin'})
            .then(result => result.json())
            .then(accounts =>
                this.setState({
                    selectedTeamId: selectedTeamId,
                    selectedTeamAccounts: accounts
                })
            );
    }

    accountOptions() {
        let excludedAccountIds = [];
        if (this.props.teamIdsToExcludedAccountIds.hasOwnProperty(this.state.selectedTeamId)) {
            excludedAccountIds = this.props.teamIdsToExcludedAccountIds[this.state.selectedTeamId];
        }

        let accountOptions = this.state.selectedTeamAccounts
            .filter(account => !excludedAccountIds.includes(account.id))
            .map(account => <option value={account.id} key={account._gid}>{account.name}</option>);

        accountOptions.push(
            <option key="unassigned" value="">Unassigned</option>
        );

        return accountOptions;
    }

    onAccountChange(event) {
        let selectedAccountId = event.target.value;

        this.setState(oldState => ({ ...oldState, selectedAccountId: selectedAccountId }));
    }

    render() {
        return (
            <div>
                <div>
                    <label htmlFor={this.props.teamSelectName}>Team</label>
                    <select name={this.props.teamSelectName}
                            value={this.state.selectedTeamId}
                            onChange={this.onTeamChange.bind(this)}
                    >
                        { this.teamOptions() }
                    </select>
                </div>

                <div>
                    <label htmlFor={this.props.accountSelectName}>User</label>
                    <select name={this.props.accountSelectName}
                            value={this.state.selectedAccountId}
                            onChange={this.onAccountChange.bind(this)}
                    >
                        { this.accountOptions() }
                    </select>
                </div>
            </div>
        );
    }
}

TeamAndAccountSelect.propTypes = {
    teamSelectName: PropTypes.string.isRequired,
    accountSelectName: PropTypes.string.isRequired,
    allTeams: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedTeamId: PropTypes.number.isRequired,
    selectedTeamAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedAccountId: PropTypes.number,
    teamIdsToExcludedAccountIds: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number))
};

TeamAndAccountSelect.defaultProps = {
    teamIdsToExcludedAccountIds: {}
};

export default TeamAndAccountSelect
