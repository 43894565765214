import React from 'react'

class DialogComponent extends React.Component {

    render() {
        return (
            <div>
                <dialog aria-describedby={this.props.dialogDescribedby || 'dialog-component'}>
                    <header>
                        <h2>{this.props.header}</h2>
                    </header>
                    <div>{this.props.body}</div>
                    <footer>
                        <button type="button" onClick={this.props.toggleDialog}>Close</button>
                        {this.props.footer}
                    </footer>
                </dialog>
            </div>
        )
    }
}

export default DialogComponent
