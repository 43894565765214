import React from 'react';
import PropTypes from 'prop-types';
import HelpPopup from './HelpPopup.jsx';

class TextualInputPlus extends React.Component {
    constructor(props) {
        super(props);
        // When the contents of a textarea are posted, embedded newlines should be transmitted as CR LF pairs.
        // So browser reports the length of the contents as if newlines cost 2 characters.
        this.state = {
            value: this.props.initialValue.replace(/\n/g, ''),
            focused: false
        };

        this._characterCounterId = `${this.props.id}_character_counter`;
        this._pulseClass = 'character_limit_exceeded';
        this._pulseTimeout = 400;
    }

    onFocus() {
        this.setState({focused: true});
    }

    onBlur() {
        this.setState({focused: false});
    }

    onChange(event) {
        let newValue = event.target.value;

        if (this.props.showCounter && this.props.characterLimit && newValue.length > this.props.characterLimit) {
            this.pulseCharacterCounter();
        } else {
            this.setState({value: newValue});
        }
    }

    pulseCharacterCounter() {
        const counterElement = document.querySelector(`#${this._characterCounterId}`);

        if (counterElement) {
            counterElement.classList.add(this._pulseClass);
            window.setTimeout(() => counterElement.classList.remove(this._pulseClass), this._pulseTimeout);
        }
    }

    renderLabel() {
        if (this.props.showLabel) {
            return (
                <label htmlFor={this.props.id} data-required={this.props.required}>
                    {this.props.label}
                </label>
            );
        } else {
            return null;
        }
    }

    renderCharacterCounter() {
        if (!(this.props.showCounter && this.state.focused)) {
            return null;
        }

        if (this.props.characterLimit) {
            return (
                <span id={this._characterCounterId}>
                    {this.state.value.length} / {this.props.characterLimit}
                </span>
            );
        } else {
            return (
                <span id={this._characterCounterId}>
                    {this.state.value.length} {this.state.value.length === 1 ? 'character' : 'characters'}
                </span>
            );
        }
    }

    renderHelpPopup() {
        if (!(this.props.popup && this.props.popup.id && this.props.popup.message)) {
            return null;
        }

        return <HelpPopup {...this.props.popup}/>;
    }

    renderInput() {
        switch (this.props.type) {
            case 'text_field':
                return <input type="text"
                              value={this.state.value}
                              onChange={this.onChange.bind(this)}
                              onFocus={this.onFocus.bind(this)}
                              onBlur={this.onBlur.bind(this)}
                              name={this.props.name}
                              id={this.props.id}
                              {...this.props.inputOptions}
                       />;
            case 'text_area':
                return <textarea value={this.state.value}
                                 onChange={this.onChange.bind(this)}
                                 onFocus={this.onFocus.bind(this)}
                                 onBlur={this.onBlur.bind(this)}
                                 name={this.props.name}
                                 id={this.props.id}
                                 {...this.props.inputOptions}
                       />;
            default:
                throw TypeError(`Unrecognized type: ${this.props.type}`);

        }
    }

    render() {
        if (this.props.renderInline) {
            return (
                <div role="row" {...this.props.wrapperOptions}>
                    {this.renderLabel()}
                    {this.renderHelpPopup()}
                    {this.renderInput()}
                    {this.renderCharacterCounter()}
                </div>
            );
        } else {
            return (
                <div {...this.props.wrapperOptions}>
                    <div role="row">
                        {this.renderLabel()}
                        {this.renderHelpPopup()}
                        {this.renderCharacterCounter()}
                    </div>
                    {this.renderInput()}
                </div>
            );
        }
    }
}

TextualInputPlus.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text_field', 'text_area']).isRequired,
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    required: PropTypes.bool,
    showLabel: PropTypes.bool,
    showCounter: PropTypes.bool,
    renderInline: PropTypes.bool,
    characterLimit: PropTypes.number,
    inputOptions: PropTypes.object,
    wrapperOptions: PropTypes.object,
    popup: PropTypes.shape({
        id: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }),
};

TextualInputPlus.defaultProps = {
    initialValue: '',
    required: false,
    showLabel: true,
    showCounter: false,
    renderInline: false,
    inputOptions: {},
    wrapperOptions: {},
};

export default TextualInputPlus;
