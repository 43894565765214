import React, { Component } from 'react';

class TableHeader extends React.Component {

    sortTableOnClick() {
        this.props.sortHandler(this.props.headerkey);
    }

    render() {
        let optionalProps = {};
        if (this.props.sorted) {
            optionalProps.sorted = this.props.sorted;
        }
        let headerName = this.props.sortable
            ? <a onClick={this.sortTableOnClick.bind(this)}>{this.props.name}</a>
            : this.props.name
        return (
            <th scope="col" title={this.props.name} {...optionalProps} key={this.props.name}>
                {headerName}
            </th>
        )
    }
}

export default TableHeader;