import React from 'react'

class IncidentRequirementSelect extends React.Component {
    constructor(props) {
        super(props);

        let selectedFieldId = props.selectedRequirement.split('_incident_field_')[1];

        this.state =
            {
                selectedRequirement: props.selectedRequirement,
                selectedRequirementValue: props.selectedRequirementValue,
                currentValueOptions: props.allRequirementValues[selectedFieldId] || {data: []},
                isIncidentField: !!selectedFieldId
            }
    }

    changeSelectedRequirement(event) {
        let selectedFieldId = event.target.value.split('_incident_field_')[1];

        this.setState({
            selectedRequirement: event.target.value,
            currentValueOptions: this.props.allRequirementValues[selectedFieldId] || {data: []},
            isIncidentField: !!selectedFieldId
        });
    }

    optionsMapper(option) {
        return (
            <option value={option[1]} key={option}>
                {option[0]}
            </option>
        );
    }

    requirementSelect() {
        return (
            <div>
                <label htmlFor='settings_incident_requirement_requirement'>Requirement</label>
                <select value={this.state.selectedRequirement}
                        onChange={this.changeSelectedRequirement.bind(this)}
                        name='settings_incident_requirement[requirement]'
                        id='settings_incident_requirement_requirement'>
                    {this.props.allRequirements.map(this.optionsMapper)}
                </select>
            </div>
        );
    }

    valueSelect() {
        return (
            <div>
                <label>Value</label>
                <select defaultValue={this.state.selectedRequirementValue}
                        name='settings_incident_requirement[required_value_id]'>
                    <option value=''>Any</option>
                    {this.state.currentValueOptions.data.map(this.optionsMapper)}
                </select>
            </div>
        );
    }

    render() {
        if (this.state.isIncidentField) {
            return (
                <div>
                    {this.requirementSelect()}
                    {this.valueSelect()}
                </div>
            );
        }
        else {
            return this.requirementSelect();
        }
    }
}

export default IncidentRequirementSelect
