// Show dialog with content big enough to read.
import React from 'react'
import DialogComponent from './DialogComponent.jsx';

class MagnifyTextComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {clicked: false};
    }

    toggleDialog(e) {
        this.setState({clicked: !this.state.clicked});
    }

    showDialog(e) {
        this.setState({clicked: true});
    }

    render() {
        let message = this.props.content || 'No description available.';
        let body = <p>{message}</p>;
        let dialog = this.state.clicked ?
            <DialogComponent body={body} header={this.props.header}
                             dialogDescribedby={'dialog-magnify-component'}
                             toggleDialog={this.toggleDialog.bind(this)}/> : null;

        return (
            <div>
                <a href="#" hasdialog="description" onClick={this.showDialog.bind(this)}>View</a>
                {dialog}
                <p title="description">{message}</p>
            </div>
        )
    }
}

export default MagnifyTextComponent
