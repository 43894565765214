// Show dialog with textarea big enough to edit long content.
import React from 'react'
import PropTypes from 'prop-types'
import DialogComponent from "./DialogComponent.jsx";

class MagnifyTextEditComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clicked: false, dialogContent: props.content || '',
            formContent: props.content || ''
        }
    }

    toggleDialog() {
        this.setState(oldState => ({clicked: !oldState.clicked}));
    }

    showDialog() {
        this.setState(oldState => ({clicked: true, dialogContent: oldState.formContent}));
    }

    copyChanges(contentFromChild) {
        this.setState({formContent: contentFromChild})
    }

    handleDialogChange(event) {
        this.setState({dialogContent: event.target.value});
    }

    handleFormChange(event) {
        this.setState({formContent: event.target.value});
    }

    finishHandler(event) {
        this.copyChanges(this.state.dialogContent);
        this.toggleDialog(event);
    }

    render() {
        let body = <textarea rows={this.props.rows}
                             cols={this.props.cols}
                             value={this.state.dialogContent}
                             onChange={this.handleDialogChange.bind(this)}/>;

        let footer = <button type="button" onClick={this.finishHandler.bind(this)}>Finished</button>;

        let magnifyLink = null;
        if (this.props.showMagnifyLink) {
            magnifyLink = <a href="#" hasdialog="edit_description" onClick={this.showDialog.bind(this)}>Edit</a>;
        }

        let dialog = null;
        if (this.state.clicked) {
            dialog = <DialogComponent {...this.props}
                                      body={body}
                                      footer={footer}
                                      dialogDescribedby={'dialog-magnify-component'}
                                      toggleDialog={this.toggleDialog.bind(this)}
                                      copyChanges={this.copyChanges.bind(this)}/>;
        }

        return (
            <div>
                {magnifyLink}

                {dialog}

                <label>Description</label>

                <textarea rows={this.props.rows}
                          name={this.props.formNameAttribute}
                          value={this.state.formContent}
                          onChange={this.handleFormChange.bind(this)}/>
            </div>
        )
    }
}

MagnifyTextEditComponent.defaultProps = {
    rows: 12,
    cols: 100,
    content: ""
};

MagnifyTextEditComponent.propTypes = {
    content: PropTypes.string,
    header: PropTypes.string.isRequired,
    formNameAttribute: PropTypes.string.isRequired,
    showMagnifyLink: PropTypes.bool.isRequired,
    rows: PropTypes.number,
    cols: PropTypes.number,
};

export default MagnifyTextEditComponent
