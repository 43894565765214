import React, {Component} from 'react';
import PropTypes from 'prop-types';

class EmailTemplateTypeSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTemplateId: props.selectedTemplateId,
            selectedTags: []
        };
        let selectedType = this.props.allTemplateTypes.find((ele) => ele.id == props.selectedTemplateId).name;
        if (props.noEmailAttachTypes.includes(selectedType)){
            document.getElementById('attach-message').classList.add('d-none')
        };
    }

    typeOptions() {
        return this.props.allTemplateTypes.map(function (type) {
            return <option value={type.id} key={type.id}>{tc.text_utils.camelToTitle(type.name)}</option>;
        });
    }

    onTypeChange(event) {
        let selectedTemplateId = event.target.value;
        let tags = this.props.allTemplateTypes.find((ele) => ele.id == selectedTemplateId).tags;

        this.setState(oldState => ({...oldState, selectedTemplateId: selectedTemplateId, selectedTags: tags}));
        let selectedType = this.props.allTemplateTypes.find((ele) => ele.id == selectedTemplateId).name;
        let checkBoxDiv = document.getElementById('attach-message');
        (checkBoxDiv && (this.props.noEmailAttachTypes.includes(selectedType))) ? checkBoxDiv.classList.add('d-none') : checkBoxDiv.classList.remove('d-none');

    }

    componentDidMount() {
        let tags = this.props.allTemplateTypes.find((ele) => ele.id == this.state.selectedTemplateId).tags;
        this.setState({selectedTags: tags});
    }

    render() {
        return (
            <span>
                <label htmlFor={this.props.typeSelectName}>Template type:</label>
                <select name={this.props.typeSelectName}
                        value={this.state.selectedTemplateId}
                        disabled={this.props.disabled}
                        onChange={this.onTypeChange.bind(this)}
                >
                    {this.typeOptions()}
                </select>
                <br/>
                <label>Tags:</label>
                <p> {this.state.selectedTags.join(' | ')} </p>
            </span>
        );
    }
}

EmailTemplateTypeSelect.propTypes = {
    allTemplateTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedTemplateId: PropTypes.number.isRequired,
    typeSelectName: PropTypes.string.isRequired
};

export default EmailTemplateTypeSelect;
